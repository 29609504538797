import React from "react";
import { Link } from "gatsby";

import auditLogo from "../../images/auditReporting.svg";
import gatewayLogo from "../../images/paymentGateway.svg";
import invoiceLogo from "../../images/invoiceSystem.svg";

import styles from "./Solutions.module.scss";

const Solutions = ({ language, data }) => {
  return (
    <div className={styles.solutionsSelectorMain}>
      <Link
        className={styles.navItem}
        activeClassName={styles.activeNavItem}
        to={`/${language}/invoice`}
      >
        <div className={styles.solutionsDetailsMain}>
          <div className={styles.solutionsIcon}>
            <img src={invoiceLogo} width="50" alt="" />
          </div>
          <div className={styles.solutionsTextWrap}>
            <div className={styles.solutionsTitle}>{data.invoicesystem}</div>
            <div className={styles.solutionsDescription}>
              {data.invoicesystemsubtitle}
            </div>
          </div>
        </div>
      </Link>
      <Link
        className={styles.navItem}
        activeClassName={styles.activeNavItem}
        to={`/${language}/gateway`}
      >
        <div className={styles.solutionsDetailsMain}>
          <div className={styles.solutionsIcon}>
            <img src={gatewayLogo} width="50" alt="" />
          </div>
          <div className={styles.solutionsTextWrap}>
            <div className={styles.solutionsTitle}>{data.paymentgateway}</div>
            <div className={styles.solutionsDescription}>
              {data.paymentgatewaysubtitle}
            </div>
          </div>
        </div>
      </Link>
      <Link
        className={styles.navItem}
        activeClassName={styles.activeNavItem}
        to={`/${language}/audit`}
      >
        <div className={styles.solutionsDetailsMain}>
          <div className={styles.solutionsIcon}>
            <img src={auditLogo} width="50" alt="" />
          </div>
          <div className={styles.solutionsTextWrap}>
            <div className={styles.solutionsTitle}>{data.auditreporting}</div>
            <div className={styles.solutionsDescription}>
              {data.auditreportingsubtitle}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default React.memo(Solutions);
