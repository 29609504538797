import React, { useState, useCallback } from "react";
import { Link } from "gatsby";
import cx from "classnames";

import Hamburger from "../Hamburger";
import LanguageSelect from "../LanguageSelect";
import styles from "./MobileMenu.module.scss";

const MobileMenu = ({ language, data, activeLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => setIsOpen((v) => !v));

  return (
    <>
      <Hamburger
        className={styles.hamburger}
        isOpen={isOpen}
        onClick={onOpen}
      />
      <ul className={cx(styles.mobileMenu, { [styles.opened]: isOpen })}>
        <li>
          <Link to={`/${language}/`} activeClassName={styles.active}>
            {data.home}
          </Link>
        </li>
        <li>
          <span className={cx(styles.navItem, styles.disabledUnderline)} to="#">
            {data.solutions}
            <ul className={styles.subMenu}>
              <li>
                <Link className={styles.navItem} to={`/${language}/invoice`}>
                  {data.invoicesystem}
                </Link>
              </li>
              <li>
                <Link className={styles.navItem} to={`/${language}/gateway`}>
                  {data.paymentgateway}
                </Link>
              </li>
              <li>
                <Link className={styles.navItem} to={`/${language}/audit`}>
                  {data.auditreporting}
                </Link>
              </li>
            </ul>
          </span>
        </li>
        <li>
          <a href="https://demo.paylican.com/invoice-system-client">
            {data.demo}
          </a>
        </li>
        <li>
          <Link to={`/${language}/contact/`} activeClassName={styles.active}>
            {data.contact}
          </Link>
        </li>
        <li>
          <LanguageSelect isMobile languageLabel={data.language} />
        </li>
      </ul>
    </>
  );
};

export default React.memo(MobileMenu);
