import React, { useEffect } from "react";

const Chat = () => {
  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    const scriptId = "crisp-chat";
    const isLoaded = !!document.getElementById(scriptId);
    if (isLoaded) {
      return;
    }
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "3b38305f-b93a-4d71-bb17-00b6819f2437";

    let script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = 1;
    script.id = scriptId;
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);
  return null;
};

export default Chat;
