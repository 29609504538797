import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { useLocation } from "@reach/router";
import Headroom from "react-headroom";
import cx from "classnames";

import MobileMenu from "../MobileMenu";
import logo from "../../images/LogoWhiteSVG.svg";
import LanguageSelect, { useLanguage } from "../LanguageSelect";

import styles from "./Header.module.scss";

import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

import Solutions from "../Solutions/Solutions";

const useMenuData = () =>
  useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fields: { slug: { eq: "menu" } } }) {
        edges {
          node {
            fields {
              slug
              language
            }
            frontmatter {
              home
              solutions
              demo
              language
              invoicesystem
              invoicesystemsubtitle
              paymentgateway
              paymentgatewaysubtitle
              auditreporting
              auditreportingsubtitle
              api
              news
              jobs
              contact
              docs
            }
          }
        }
      }
    }
  `);

const useActiveLinks = () => {
  const { pathname } = useLocation();
  const links = ["invoice", "gateway", "audit"];
  const paths = pathname.split("/").filter(Boolean);
  return {
    solutions: paths.length && links.includes(paths[paths.length - 1]),
  };
};

const Header = () => {
  const menuData = useMenuData();
  const language = useLanguage();
  const activeLinks = useActiveLinks();

  const data = menuData.allMarkdownRemark.edges.filter(
    (element) => element.node.fields.language === language
  )[0].node.frontmatter;

  return (
    <Headroom className={styles.headroom}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <Link className={styles.title} to="/">
            <img src={logo} alt="Paylican" />
          </Link>
        </div>
        <div className={styles.headerRight}>
          <MobileMenu
            data={data}
            language={language}
            activeLinks={activeLinks}
          />
          <ul className={styles.menu}>
            <li>
              <Link to={`/${language}/`} activeClassName={styles.active}>
                {data.home}
              </Link>
            </li>
            <li>
              <Tooltip
                arrow
                interactive
                tag="span"
                position="bottom"
                trigger="mouseenter"
                theme="light"
                html={<Solutions data={data} language={language} />}
              >
                <Link
                  className={cx(styles.navItem, {
                    [styles.active]: activeLinks.solutions,
                  })}
                  to="#"
                >
                  {data.solutions}
                </Link>
              </Tooltip>
            </li>
            <li>
              <a
                href="https://docs.paylican.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.docs}
              </a>
            </li>
            <li>
              <Link
                to={`/${language}/contact/`}
                activeClassName={styles.active}
              >
                {data.contact}
              </Link>
            </li>
            <li>
              <span className={styles.verticalLine} />
            </li>
            <li>
              <LanguageSelect />
            </li>
          </ul>
        </div>
      </header>
    </Headroom>
  );
};

export default React.memo(Header);
