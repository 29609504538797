import React from "react";

import Chat from "../Chat";
import Footer from "../Footer";
import Header from "../Header";

import "../../styles/index.scss";
import styles from "./Layout.module.scss";

const Layout = ({ heading, children }) => {
  return (
    <>
      <Chat />
      <div className={styles.overlay}>
        <Header />
        {heading}
      </div>
      <div className={styles.overlayEnder1} />
      <div className={styles.overlayEnder2} />
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default React.memo(Layout);
