import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import logo from "../../images/LogoWhiteSVG.svg";
import useSiteMetaData from "../../hooks/useSiteMetaData";
import { useLanguage } from "../LanguageSelect";
import linkedinSvg from "./linkedin.svg";
import styles from "./Footer.module.scss";

const useFooterData = () =>
  useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fields: { slug: { eq: "footer" } } }) {
        edges {
          node {
            fields {
              slug
              language
            }
            frontmatter {
              title
              desc
              labels {
                newProject
                solutions
                contact
                invoice
                gateway
                audit
                privacyPolicy
              }
            }
          }
        }
      }
    }
  `);

const Footer = () => {
  const data = useSiteMetaData();
  console.log(data,4)
  const language = useLanguage();
  const footerData = useFooterData().allMarkdownRemark.edges.filter(
    (e) => e.node.fields.language === language
  )[0].node.frontmatter;

  return (
    <footer className={styles.footer}>
      <div className={styles.section}>
        <div className={styles.sectionLogo}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="Paylican" />
          </Link>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.paylicanTitle}>{footerData.title}</div>
          <div className={styles.paylicanDesc}>{footerData.desc}</div>
        </div>
        <div className={styles.privacyContainer}>
          <span className={styles.copyright}>
            {`© ${new Date().getFullYear()} Paylican`}
          </span>
          <div className={styles.line} />
          <Link to={`/${language}/privacy-policy`}>
            {footerData.labels.privacyPolicy}
          </Link>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>{footerData.labels.solutions}</div>
        <div className={styles.sectionContainer}>
          <div className={styles.link}>
            <Link to={`/${language}/invoice`}>{footerData.labels.invoice}</Link>
          </div>
          <div className={styles.link}>
            <Link to={`/${language}/gateway`}>{footerData.labels.gateway}</Link>
          </div>
          <div className={styles.link}>
            <Link to={`/${language}/audit`}>{footerData.labels.audit}</Link>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>{footerData.labels.contact}</div>
        <div className={styles.sectionContainer}>
          <Link to={`/${language}/contact`} className={styles.contactBtn}>
            {footerData.labels.newProject}
          </Link>
          <img src={linkedinSvg} />
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
