import React from "react";
import cx from "classnames";

import styles from "./Hamburger.module.scss";

const Hamburger = ({ className, isOpen, onClick }) => {
  return (
    <div className={cx(styles.hamburger, className)}>
      <input type="checkbox" checked={isOpen} onChange={onClick} />
      <span className={styles.hamburgerItem} />
      <span className={styles.hamburgerItem} />
      <span className={styles.hamburgerItem} />
    </div>
  );
};

Hamburger.defaultProps = {
  isOpen: false,
};

export default React.memo(Hamburger);
