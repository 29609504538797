import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetaData from "../../hooks/useSiteMetaData";

const Head = ({ title }) => {
  const data = useSiteMetaData();
  return <Helmet title={`${title} | ${data.site.siteMetadata.title}`} />;
};

export default React.memo(Head);
